/* new version css */
.App {
  text-align: center;
  width: 100%;
  height: 100vh;
  background-color: #282c34;
}

.AppIframe {
  text-align: center;
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.main-container {
  height: 100%;
}

.wrapper {
  width: 460px;
  border-radius: 1rem;
  position: fixed;
  right: 30px;
  bottom: 30px;
}

.wrapper .header {
  background: #fff;
  color: black;
  line-height: 2.5;
  text-align: center;
  padding: 10px;
  font-size: 15px;
  border-radius: 10px 10px 0 0;
  display: grid;
  justify-content: center;
  gap: 10px;
  grid-template-columns: 35% 40% 20%;
}
.wrapper .header .title {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.wrapper .header .header-icon-container {
  display: grid;
  justify-content: right;
  align-items: center;
  grid-template-columns: auto auto auto;
  gap: 3px;
}
.wrapper .box {
  background-color: #fff;
  padding: 10px 15px;
  min-height: 600px;
  max-height: 600px;
  border: 1px solid #ededed;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
}

.message-item {
  display: flex;
  justify-content: left;
  margin: 15px 20px 15px 20px;
}

.message-item .msg {
  background: #f5f5f5;
  color: #000;
  border-radius: 10px;
  max-width: 80%;
  min-width: auto;
  padding: 15px;
  font-size: small;
  text-align: left;
  line-height: 1.1rem;
}

.content-copy-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  /* cursor: pointer; */
}

.message-item.right {
  justify-content: right;
  margin-top: 15px;
}

.message-item.right .msg {
  background: #0078d4;
  color: white;
  max-width: 80%;
  min-width: auto;
  padding: 15px;
  font-size: small;
  text-align: left;
  border-radius: 10px;
}
.uploaded-file-list-area {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.uploaded-file-list-items {
  width: 90%;
  display: grid;
  grid-template-columns: 30px auto;
  gap: 10px;  
  align-items: center; 
  border-radius: 10px;
}

.file-list-area {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  background-color: #f5f5f5;
}
.file-list-area.attached {
 padding:10px
}

.file-list-items {
  width: 90%;
  display: grid;
  grid-template-columns: 30px auto 30px;
  gap: 10px;  
  align-items: center; 
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.wrapper .typing-area {
  /* width: 100%; */
  background: #f5f5f5;
  align-items: center;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  display: grid;
  justify-content: space-around;
  grid-template-columns: auto 70% auto auto;
}

.wrapper .typing-area.typing {
  grid-template-columns: auto 80% auto;
}

.wrapper .typing-area .input-field {
  text-align: left;
  display:flex;
}

.prompt-textarea {
  background-color: transparent;
  padding: 10px 0;
  width: 100%;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  font-family: "Poppins", sans-serif;
  /* transition: 0.3s all ease; */
}

/* .wrapper .typing-area .input-field input:focus {
  border-color: #0078d4;
} */

.file-upload-btn {
  padding: 0.5rem;
  cursor: pointer;
}
.recording-btn {
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
  background: #0078d4;
  border: 1px solid #0078d4;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.recording {
  background: #FB4646;
  border: 1px solid #FB4646;
  position: relative;
  box-shadow: 0 0 0 0 #FB4646;
  cursor: pointer;
  animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
  
  &:hover {
    animation: none;
  }
}
@keyframes pulse {to {box-shadow: 0 0 0 10px rgba(90, 0, 141, 0);}}

.sound-btn {
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
  background: #000000;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.QA-btn {
  position: fixed;
  width: 54px;
  height: 54px;
  right: 35px;
  bottom: 35px;
  padding: 7px 5px;
  border-radius: 8px;
  color: #fff;
  outline: none;
  cursor: pointer;
  transition: 0.3s all ease;
  background: #467afb;
  border: 1px solid #467afb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-icon-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
}

div.avatar {
  position: absolute;
  width: 150px;
  height: 150px;
  box-shadow: 0 5px 3px -3px rgb(0 0 0 / 0.1), 0 4px 3px -4px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin-top: -70px;
  margin-left: 20px;
  z-index: 5000;
}

.box .message-item.start {
  margin-top: 40px;
}

.avatar-list-container {
  width: 80%;
}
.avatar-list {
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid: column;
  gap: 8px;
}
.avatar-list-items {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border-radius: 0.3rem;
  cursor: pointer;
}
.avatar-list-items.selected {
  background-color: #0078d4;
  color: white;
}
.avatar-list-items img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.avatar-list-items span {
  margin-left: 10px;
  font-size: 14px;
}
.spinner-container {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 100%;
  display: flex;
  align-items: right;
  justify-content: right;
}
.spinner {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3px solid #080808;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
  
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.setting-content {
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  position:absolute;
  background-color: white;
  right: 10px;
  top:60px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
}
