body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.main {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}





/* 
.list-wrapper {
  max-width: 40rem;
  display: flex;
  flex: 1 1 0%;
  margin-left: auto;
  margin-right:auto;
}

.list-img-wrapper {
  align-items: flex-end;
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
  position: relative;
}

.list-img-wrapper-round {
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
}
.list-text-wrapper {
  position: relative;
  display: flex;
  width:100%;
  flex-direction: column;
  margin-left: 5px;
}
.list-text-name {
  font-weight: 600;
  user-select: none;
  -webkit-user-select: none;
  text-align: left;
}
/* .list-text-content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex-grow: 1;    
} */
/* .list-text-content {
  text-align: left;
  min-height: 20px;
  gap: .75rem;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.input-container {
  position: relative;
  width:700px;
  min-width:300px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1.25rem;
  padding-bottom: 2rem;

}
.prompt-wrapper {
  border: 1px solid #9b9b9b;
  border-radius: 1rem;
  position:relative;
  margin-top: 0.75rem;
  display:flex;
  min-height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.file-input-icon {
  padding: 0.25rem;
  cursor: pointer;   
  position: absolute;
  left:0.75rem;
  bottom:12px;
  cursor: pointer;
  position:absolute;
  background-color: transparent;   
  padding:5px 10px;
}

.file-input {
  display: none;
}



.submit-btn {
  cursor: pointer;
  position:absolute;
  background-color: transparent;
  right: .75rem;
  bottom:14px;
  border: 1px solid black;
  padding:5px 10px;
  border-radius: 5px;
} */
